import React, { useState } from "react";
import "./css/App.css";
import Modal from "./common/Modal";
import ConfigManager from "./common/Configuration";
import circleChecked from "./icons/circle-checked.png";
import circleX from "./icons/circle-x.png";
import checkImg from "./icons/check_mark_2x.png";

function App() {
  const [searchKey, setSearchKey] = React.useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const changeSearchText = (e: any) => {
    setSearchKey(e.target.value);
  };

  const onClickedSearch = () => {
    if (searchKey === null || searchKey === undefined || searchKey === "") {
      // 에러 팝업
      openModal("검색어를 입력해 주세요.");
      return;
    }

    searchAddress(searchKey).then(
      (result) => {
        setSearchResult(result);
      },
      (error) => {
        openModal("데이터를 불러오지 못했습니다.\n잠시 후 다시 시도해주세요.");
      }
    );
  };

  async function searchAddress(query: string): Promise<[]> {
    let url =
      ConfigManager.getInstance().getConfig("apiUrl") +
      "/address/service-area?query=" +
      query;
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      data.sort((a: any, b: any) => {
        return a.serviceArea === b.serviceArea ? 0 : a.serviceArea ? -1 : 1;
      });

      setLoading(false);
      console.log(data);
      return data;
    } catch (error) {
      console.error("Fetch error: ", error);
      throw error;
    }
  }

  const openModal = (message: string) => {
    setMessage(message);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const makeJibunAddress = (address: string) => {
    if (address === null || address === undefined || address === "") {
      return "";
    }

    const splittedAddress = address.split(" ");

    if (splittedAddress.length <= 2) {
      return `(${address})`;
    } else {
      return `(${splittedAddress.slice(2)})`;
    }
  };

  // const onClickedAddressRow = (data: any) => {
  //   setMessage(
  //     `${data.roadAddress} ${makeJibunAddress(data.jibunAddress)}\n\n${
  //       data.serviceArea
  //         ? "O 핑퐁 서비스 지역입니다"
  //         : "X 핑퐁 서비스지역이 아닙니다"
  //     }`
  //   );
  //   setModalOpen(true);
  // };

  const activeEnter = (e: any) => {
    if (e.key === "Enter") {
      onClickedSearch();
    }
  };

  const onClickedClear = () => {
    setSearchKey("");
  };

  return (
    <div className="App">
      {/* </Modal> */}
      <Modal open={modalOpen} close={closeModal} message={message} />
      <div className="top">
        <div className="logo_wrap">
          <div className="logo" />
        </div>
      </div>
      <div className="body_wrap">
        <div className="search_wrap">
          <div className="input_wrap">
            <input
              type="text"
              id="search_key"
              className="input_search_key"
              placeholder="주소 혹은 건물명으로 검색하세요."
              value={searchKey}
              onChange={changeSearchText}
              onKeyDown={(e) => activeEnter(e)}
            />
            <div className="btn_input_clear" onClick={() => onClickedClear()}>
              지우기
            </div>
          </div>
          <div className="info_wrap">
            <div className="info_text">
              * 야간출입이 불가한
              관공서/군사지역/학교/병원/시장/백화점/산업단지/도서산간지역은
              당일배송이 불가합니다.
            </div>
          </div>
          <div className="btn_wrap">
            <div className="btn_search" onClick={() => onClickedSearch()}>
              검색하기
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="loader_wrapper">
            <div className="loader" />
          </div>
        ) : searchResult.length > 0 ? (
          <div className="result_wrap">
            {
              <div className="result_list">
                {searchResult.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="result_item"
                      // onClick={() => onClickedAddressRow(item)}
                    >
                      <div className="result_item_address">
                        <div className="address">
                          <div className="address_road">{item.roadAddress}</div>
                          <div className="spacer_hotizontal_4" />
                          <div className="address_jibun">
                            {makeJibunAddress(item.jibunAddress)}
                          </div>
                        </div>
                        <div className="address_detail">{item.detail}</div>
                        <div className="address_detail">({item.zipcode})</div>
                      </div>
                      <div className="spacer" />
                      <div className="result_service_area">
                        {item.serviceArea ? (
                          <div className="service_area_result">
                            <img
                              className="service_result_img"
                              src={circleChecked}
                              alt="서비스 지역 이미지 O"
                            />
                            <div className="spacer_vertical_4" />
                            <div className="service_area_result_text">
                              <div className="ok">당일배송 가능</div>
                            </div>
                          </div>
                        ) : (
                          <div className="service_area_result">
                            <img
                              className="service_result_img"
                              src={circleX}
                              alt="서비스 지역 이미지 X"
                            />
                            <div className="spacer_vertical_4" />
                            <div className="service_area_result_text">
                              <div className="no">당일배송 불가</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            }
          </div>
        ) : (
          <div className="empty">검색 결과가 없습니다.</div>
        )}
      </div>
    </div>
  );
}

export default App;
