import "../css/Modal.css";

const Modal = (props: any) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, message } = props;

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? "openModal modal" : "modal"}>
      {open ? (
        <section>
          <main>
            <p className="desc_alarm">
              {typeof message === "string"
                ? message.split("\n").map((letter) => (
                    <>
                      {letter}
                      <br />
                    </>
                  ))
                : message}{" "}
            </p>
          </main>
          <footer>
            <button className="close" onClick={close}>
              확인
            </button>
          </footer>
        </section>
      ) : null}
    </div>
  );
};

export default Modal;
